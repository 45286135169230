<template>
	<div class="first-section">
		<cms-multi-section value="content"/>
	</div>
</template>
<script>
import { pageMixin } from '~/website/front/core/pages/page.mixin'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
export default {
	components: {
		CmsMultiSection
	},
	props: {
		prefix: String
	},
	mounted () {
		document.querySelector('#chat-application').classList.add('b2b')
	},
	destroyed () {
		document.querySelector('#chat-application').classList.remove('b2b')
	},
	mixins: [
		pageMixin
	]
}
</script>
<style lang="scss" scoped>
.first-section {
	padding-top: 5rem;
	@include media-breakpoint-up(xl) {
		padding-top: 7.9rem;
	}
}

::v-deep .realization-categories {
	.card.link {
		grid-column: initial;
	}
}

::v-deep.wrapper-form-side {
	background-color: red;
}
</style>
<style>
#chat-application {
	bottom: 0;
}
</style>
