import { Page } from '~/cms/front/core/pages/Page'
import component from './BussToBussPage.vue'

class BussToBussPage extends Page {
	static alias = 'buss-to-buss'

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('buss-to-buss')
	}

	component = component
}

export { BussToBussPage }
